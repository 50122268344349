import translations from '../../../../utils/translations'
import { customTypes, FIELD_COMPONENT_TYPES, FieldPreset } from '../../../../constants/field-types'
import { iconNames } from './icons/types'
import { FieldCollectionType } from '../../../../constants/wixcode'
import {
  FieldProperties,
  FieldRenderConfig,
  PluginsFieldExtraData,
} from './field-types-data'
import * as _ from 'lodash'
import { CUSTOM_FIELD } from '../../../../constants/crm-types-tags'
import ExperimentsService from '../../../../utils/experiments-service'
import { baseStyleDefaults } from './constants'
import { ComponentStructure } from '../../../../constants/api-types'

interface IMetadata {
  fieldType: FieldPreset
  customFields: customTypes[]
  icon: iconNames
  ADI_titleTranslationKey: string
  allowOnlyOnce: boolean
  disableCollectionSync: boolean
  pluginsExtraData?: PluginsFieldExtraData
  renderConfig?: FieldRenderConfig
}

export abstract class IGeneralField {
  protected translate(key, options = {}): string {
    return translations.t(key, options)
  }

  protected isContactsListTabExperiment() {
    return ExperimentsService.isEnabled('specs.cx.FormBuilderContactsListTab')
  }

  // Accessor to base class
  public abstract base: IGeneralField

  // Field Props
  public abstract fieldType: FieldPreset
  public abstract role: string
  protected abstract icon: iconNames
  protected abstract componentType: FIELD_COMPONENT_TYPES

  public get collectionFieldType(): FieldCollectionType {
    return null
  }

  public get customFields() {
    return [customTypes.TEXT]
  }

  public get crmType() {
    return CUSTOM_FIELD
  }

  public get crmTag() {
    return null
  }

  public get manifest() {
    return {}
  }

  public get designMapping() {
    return null
  }

  public get layoutComponentProps() {
    return []
  }

  protected get data() {
    return {}
  }

  protected get props() {
    return {}
  }

  protected get layout() {
    return {}
  }

  protected get additionalData() {
    return {}
  }
  protected get skin() {
    return null
  }

  protected get responsiveSkin() {
    return this.skin
  }

  protected get styleProperties(){
    return { properties: {} }
  }

  public get supportsLabel() {
    return false
  }

  public get supportedInResponsive() {
    return false
  }

  public get fieldWithOptions() {
    return false
  }

  public get alwaysShowLabel() {
    return false
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${this.fieldType}`)
  }

  protected get ADI_titleTranslationKey() {
    return `adi.${this.fieldType}FieldEditPanel.title`
  }

  public get allowOnlyOnce() {
    return false
  }

  public get disableCollectionSync() {
    return false
  }

  protected get renderConfig(): Partial<FieldRenderConfig> | null {
    return null
  }

  protected get pluginsExtraData(): Partial<PluginsFieldExtraData> | null {
    return null
  }

  get metadata(): IMetadata {
    return {
      customFields: this.customFields,
      icon: this.icon,
      ADI_titleTranslationKey: this.ADI_titleTranslationKey,
      allowOnlyOnce: this.allowOnlyOnce,
      disableCollectionSync: this.disableCollectionSync,
      renderConfig: this.renderConfig,
      pluginsExtraData: this.pluginsExtraData,
      fieldType: this.fieldType,
    }
  }

  get properties(): FieldProperties {
    const data = _.merge({}, this.base.data, this.data)
    const props = _.merge({}, this.base.props, this.props)
    const layout = _.merge({}, this.base.layout, this.layout)

    const extraData = {
      ...(_.isEmpty(data) ? {} : { data }),
      ...(_.isEmpty(props) ? {} : { props }),
      ...(_.isEmpty(layout) ? {} : { layout }),
      ...this.additionalData
    }

    const connectionConfig: any = {
      ...(_.isEmpty(this.crmType) ? {} : { crmType: this.crmType }),
      ...(_.isEmpty(this.crmLabel) ? {} : { crmLabel: this.crmLabel }),
      ...(_.isEmpty(this.crmTag) ? {} : { crmTag: this.crmTag }),
      ...(_.isEmpty(this.collectionFieldType) ? {} : { collectionFieldType: this.collectionFieldType }),
      ...({ fieldType: this.fieldType }),
    }

    return {
      ...(_.isEmpty(this.collectionFieldType)
        ? {}
        : { collectionFieldType: this.collectionFieldType }),
      componentType: this.componentType,
      extraData: {
        role: this.role,
        ...{ connectionConfig },
        ...extraData,
      },
    }
  }


  public fieldStructure(isResponsive = false): Partial<ComponentStructure> {
    const skin = isResponsive ? this.responsiveSkin : this.skin
    const baseStyle: ComponentStyle = _.assign( {}, baseStyleDefaults,{
      style: this.styleProperties,
      componentClassName: this.componentType,
      skin,
    })

    const style: ComponentStyle = isResponsive ? {
      type: 'StylesInBreakpoint',
      skin,
      stylesInBreakpoints: [{
        ...baseStyle,
        breakpoint: ''
      }]
    } : baseStyle

    return {
      type: 'Component',
      skin,
      componentType: this.componentType,
      style,
      ...this.properties.extraData,
    }
  }
}
