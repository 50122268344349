import * as _ from 'lodash'
import CoreApi from '../core-api'
import { withBi } from '../decorators'
import { EVENTS } from '../../../constants/bi'
import { createPanelDefs } from '../services/panel-definitions'
import { ROLE_FORM } from '../../../constants/roles'
import { BillingPanelReferrer, upgradeAlertType } from '../../../constants/premium'
import { PanelName, NativePanelName } from './consts/panel-names'
import { nativePanelOverrides } from './consts/native-panel-overrides'
import { getBaseUrl } from '../../../../src/utils/utils'
import { PANEL as ADI_PANEL } from '../../../panels/adi-panel/constants'
import { PanelEventName } from './consts/panel-event-names'
import { PanelEvent } from './types'
import { EMAIL_SUBMISSIONS_HELP_ID } from '../../../panels/form-settings-panel/constants/help-ids'
import { getFieldRenderConfigFields } from '../preset/fields/field-types-data'
import { ComponentConnection } from '../../../constants/api-types'
import { InitialPanelData } from '../../../panels/commons/base-panel'
import { getFormPlugins } from '../plugins/utils'
import { FormPlugin } from '../../../constants/plugins';

export default class ManagePanelsApi {
  private biLogger: any
  private boundEditorSDK: any
  private editorSDK: any
  private coreApi: CoreApi
  private experiments: any
  private panelEvents: PanelEvent[]

  constructor(boundEditorSDK, editorSDK, coreApi: CoreApi, { experiments, biLogger }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.editorSDK = editorSDK
    this.experiments = experiments
    this.panelEvents = []
  }

  public closePanel(panelToken, payload = null) {
    return this.editorSDK.editor.closePanel(panelToken, payload)
  }

  public openHelpPanel(helpId, onOpen = _.noop) {
    onOpen()
    return this.boundEditorSDK.editor.openHelpPanel({ helpId })
  }

  public async openModalPanel(panelName: string, onOpen = _.noop) {
    const msid = await this.coreApi.getMetaSiteId()
    const isResponsive = this.coreApi.isResponsive()
    this.boundEditorSDK.editor.openModalPanel(createPanelDefs(msid)[panelName]({ experiments: this.experiments, isResponsive }))
    onOpen()
  }

  @withBi({ startEvid: EVENTS.PANELS[PanelName.NEW_FORM_SETTINGS].NOTIFICATIONS_TAB_HELP })
  public openEmailSubmissionsHelpPanel(_biData = {}) {
    this.openHelpPanel(EMAIL_SUBMISSIONS_HELP_ID)
  }

  // _panelName is deprecated. ADI is loading one single page and we need to manage all panels there
  public openAdiPanel(
    _panelName: string,
    formRef: ComponentRef,
    activePanel: ADI_PANEL = ADI_PANEL.EDIT_FORM
  ) {
    return Promise.resolve(
      `https://${getBaseUrl()}/statics/adi-panel.html?id=${formRef.id}&type=${
        formRef.type
      }&state=${activePanel}`
    )
  }

  public openFieldPanel(componentRef: ComponentRef, fieldsPanelToken, _biData = {}) {
    return this._selectField(componentRef, fieldsPanelToken)
  }

  private _selectField(componentRef: ComponentRef, fieldsPanelToken) {
    this.closePanel(fieldsPanelToken)
    return this.boundEditorSDK.selection.selectComponentByCompRef({ compsToSelect: [componentRef] })
  }

  public async openContactSyncPanel(componentRef: ComponentRef) {
    const [msid, componentConnection, plugins] = await this._fetchConfigAndMsid(componentRef)
    const { config } = componentConnection

    const panelDefinition = createPanelDefs(msid)[PanelName.CONTACT_SYNC_PANEL]({
      componentRef,
      config,
      plugins,
    })
    return this.boundEditorSDK.editor.openToolPanel(panelDefinition)
  }

  public async openGetSubscribersFirstTimePanel(): Promise<any> {
    const dontShowAgain = await this.coreApi.firstTimePanel.getSubscribersHideFirstTimePanel()
    if (dontShowAgain) {
      return
    }

    const msid = await this.coreApi.getMetaSiteId()
    return this.boundEditorSDK.editor.openToolPanel(createPanelDefs(msid)[PanelName.FIRST_TIME]())
  }

  @withBi({ startEvid: EVENTS.PANELS.addFieldPanel.OPEN_PANEL })
  public async openAddFieldPanel(formComponentRef, panelToken, _biData = {}) {
    const [msid, componentConnection, plugins] = await this._fetchConfigAndMsid(formComponentRef)
    const { config } = componentConnection

    return this.editorSDK.editor.updatePanelOptions(
      panelToken,
      createPanelDefs(msid)[PanelName.ADD_FIELD]({
        config,
        componentRef: formComponentRef,
        plugins,
      })
    )
  }

  @withBi({ startEvid: EVENTS.PANELS.upgradeAlertPanel.ACTION_CLICK })
  public closeUpgradeAlertPanel(token, _biData = {}) {
    return this.closePanel(token)
  }

  public async openPremiumBillingPanel(
    componentRef: ComponentRef,
    { referrer, alertType }: { referrer: BillingPanelReferrer; alertType: upgradeAlertType }
  ) {
    const esi = await this.coreApi.getEditorSessionId()
    const currentAscendPlan = await this.coreApi.premium.getCurrentAscendPlan()
    return this._openUpgradeAlertPanel(componentRef, alertType, {
      startBi: {
        form_comp_id: componentRef.id,
        esi,
        origin: referrer,
        current_ascend_plan: currentAscendPlan,
      },
    })
  }

  @withBi({ startEvid: EVENTS.PANELS.upgradeAlertPanel.OPEN_PANEL })
  public async openAddFormPremiumBillingPanel(alertType, _biData = {}) {
    const msid = await this.coreApi.getMetaSiteId()

    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.UPGRADE_ALERT]({
        alertType,
      })
    )
  }

  @withBi({ startEvid: EVENTS.PANELS.manageSubscribersPanel.OPEN_PANEL })
  public openDefaultManageSubscribersPanel(msid, _biData = {}) {
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.FORM_MANAGE_SUBSCRIBERS]()
    )
  }

  public async openMemberSignupPagePanel() {
    const currentPageRef = await this.editorSDK.document.pages.getCurrent()
    return this.boundEditorSDK.editor.openPagesPanel({ pageRef: currentPageRef })
  }

  public openBusinessManagerPanel(entry) {
    return this.boundEditorSDK.editor.openDashboardPanel({ closeOtherPanels: false, url: entry })
  }

  @withBi({ startEvid: EVENTS.PANELS.addFieldPanel.SELECT_FIELD_TO_ADD })
  public openBlockedFieldAlert(componentRef: ComponentRef, { referrer, alertType }, _biData = {}) {
    return this.openPremiumBillingPanel(componentRef, { referrer, alertType })
  }

  @withBi({ endEvid: EVENTS.PANELS.fieldSettingsPanel.OPEN_PANEL })
  public async openDynamicFieldSettingsPanel(
    componentRef: ComponentRef,
    onOpen = _.noop,
    _biData = {}
  ) {
    const { role, controllerRef, config } = await this.coreApi.getComponentConnection(componentRef)
    const [formComponentRef, [{ data: fieldData }]] = await Promise.all([
      this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM),
      this.coreApi.isNewContactSync()
        ? this.boundEditorSDK.components.get({
            componentRefs: [componentRef],
            properties: ['data'],
          })
        : Promise.resolve([{ data: {} }]),
    ])

    const options = {
      componentRef,
      ..._.get(nativePanelOverrides, role, {}),
    }
    const onSettingsPanelClose = this.coreApi.isNewContactSync()
      ? () => this.coreApi.fields.getAndUpdateCrmLabel(componentRef, fieldData)
      : _.noop

    this.boundEditorSDK.editor
      .openNativeComponentPanel(NativePanelName.SETTINGS, options)
      .then(onSettingsPanelClose)

    onOpen(_.merge({}, config, { formId: formComponentRef.id }))
  }

  public async openComponentPanel<T>(
    componentRef: ComponentRef,
    panelName: PanelName,
    onOpen = _.noop,
    extraInitialData: Partial<T> = {},
  ) {
    const [msid, componentConnection, plugins] = await this._fetchConfigAndMsid(componentRef)
    const { role, controllerRef, config } = componentConnection
    
    const formComponentRef =
      role == ROLE_FORM
        ? componentRef
        : await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)

    const mode = await this.boundEditorSDK.info.getEditorMode()
    const fieldRenderConfig = config.fieldType
      ? getFieldRenderConfigFields(plugins, config.fieldType)
      : {}

    const initialData: InitialPanelData<T> = {
      componentRef,
      config,
      plugins,
      ...extraInitialData,
    }

    return this.boundEditorSDK.editor.openComponentPanel(
      createPanelDefs(msid)[panelName](
        initialData,
        {
          mode,
          experiments: this.experiments,
          isResponsive: this.coreApi.isResponsive(),
          fieldRenderConfig,
        }
      )
    ).then(() => {
      onOpen(_.merge({}, config, { formId: formComponentRef.id }))
    })
  }

  @withBi({ startEvid: EVENTS.PANELS.upgradeAlertPanel.OPEN_PANEL })
  private async _openUpgradeAlertPanel(
    componentRef: ComponentRef,
    alertType: upgradeAlertType,
    _biData = {}
  ) {
    const [msid, componentConnection, plugins] = await this._fetchConfigAndMsid(componentRef)
    const { config } = componentConnection

    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.UPGRADE_ALERT]({
        componentRef,
        config,
        plugins,
        alertType,
      })
    )
  }

  public openPublishSitePopup(componentRef: ComponentRef, msid: string) {
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.PUBLISH_SITE_POP_UP](componentRef)
    )
  }

  @withBi({ startEvid: EVENTS.PANELS[PanelName.PAYMENT_WIZARD].PAYMENT_WIZARD_DISPLAYED })
  public openPaymentWizardPanel(componentRef: ComponentRef, msid, _biData = {}) {    
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.PAYMENT_WIZARD]({ 
        componentRef,
      })
    )
  }

  @withBi({
    startEvid: EVENTS.PANELS[PanelName.DELETE_PAYMENT_ALERT].REMOVE_PAYMENT_POPUP_DISPLAYED,
  })
  public openDeletePaymentPopup(componentRef: ComponentRef, msid: string, _biData = {}) {
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.DELETE_PAYMENT_ALERT]({ 
        componentRef,
      })
    )
  }

  public openClosePaymentWizardAlertPopup(componentRef: ComponentRef, msid: string) {
    return this.boundEditorSDK.editor.openToolPanel(
      createPanelDefs(msid)[PanelName.CLOSE_PAYMENT_WIZARD_ALERT]({ 
        componentRef,
      })
    )
  }

  @withBi({ startEvid: EVENTS.PANELS[PanelName.MANAGE_STEPS].OPEN_PANEL })
  public async openManageStepsPanel(componentRef: ComponentRef, _biData = {}) {
    const [msid, componentConnection, plugins] = await this._fetchConfigAndMsid(componentRef)
    const { config } = componentConnection

    return this.boundEditorSDK.editor.openComponentPanel(
      createPanelDefs(msid)[PanelName.MANAGE_STEPS]({ 
        componentRef,
        config, 
        plugins,
      })
    )
  }

  private async _fetchConfigAndMsid(componentRef: ComponentRef): Promise<[string, ComponentConnection, FormPlugin[]]> {
    const [msid, componentConnection] = await Promise.all([
      this.coreApi.getMetaSiteId(),
      this.coreApi.getComponentConnection(componentRef),
    ])
    const plugins = getFormPlugins(componentConnection)
    return [
      msid,
      componentConnection,
      plugins,
    ]
  }

  public registerPanelEvent(panelEvent: PanelEvent) {
    this.panelEvents.push(panelEvent)
  }

  public unregisterAllPanelEvents(panelToken: string) {
    this.panelEvents = this.panelEvents.filter(event => event.panelToken != panelToken)
  }

  public triggerPanelEvent(eventName: PanelEventName, payload) {
    this.panelEvents
      .filter(event => event.eventName === eventName)
      .forEach(event => event.callback(payload))
  }

  public getAllPanelEvents() {
    return this.panelEvents
  }
}
